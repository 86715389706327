<template>
    <v-container grid-list-xl v-if="!$store.getters.isLoading" fluid>
        <h1>Sessões ativas</h1>
        <v-divider class="mb-12" />
        <v-card color="primary">
            <v-card-text class="white--text">
                <div class="headline mb-2">
                    <v-icon dark>score</v-icon>
                    {{ items.length }}
                </div>
                Sessões ativas
            </v-card-text>
        </v-card>
        <v-list subheader>
            <v-list-item v-for="(item, i) in items" :key="i" class="elevation-2" color="primary">
                <v-list-item-avatar>
                    <v-icon :class="classColor(item)"> {{ icon(item) }} </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        Carregador: <strong>{{ item.charger.name }}</strong>
                    </v-list-item-title>
                    <v-list-item-subtitle>Plug: {{ item.plug }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.user">Usuário: {{ item.user.email }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.charger.point && item.charger.point.name">Local: {{ item.charger.point.name }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <router-link :to="{ path: nowPath(item.charger.name, item.plug) }" tag="v-btn">
                        <v-btn x-small :class="classColor(item)" style="width: 100%">
                            <v-icon left>remove_red_eye</v-icon>
                            Now
                        </v-btn>
                    </router-link>
                    <v-btn x-small color="warning" class="mt-2 secondary--text" style="width: 100%" @click="clear(item.id)"> Reset </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <ConfirmModal ref="confirm" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";

export default {
    mounted() {
        this.timer = setInterval(this.update, 5000);
        this.update();
    },

    components: { ConfirmModal },

    data() {
        return {
            timer: null,
            items: [],
        };
    },

    methods: {
        update() {
            this.$http.get("api/v2/session", { continuous: true }).then((result) => {
                if (result) {
                    this.items = result;
                }
            });
        },
        clear(id) {
            this.$refs.confirm
                .open("Tem certeza?", "Só faça limpeza de carregamentos com problema. A limpeza de carregamento ativo irá apagar todo os dados e as informações serão perdidas.")
                .then((confirm) => {
                    if (confirm) {
                        this.$http
                            .patch("api/v2/session", { id: id })
                            .then(() => {
                                this.update();
                            })
                            .catch((error) => {
                                this.$eventHub.$emit("msgError", error.message ? error.message : "Error ao processar requisição.");
                            });
                    }
                });
        },
    },

    computed: {
        icon() {
            return (item) => (item.status == "Charging" ? "battery_charging_full" : "power");
        },
        classColor() {
            return (item) => (item.charger.data.online == 1 ? "primary white--text" : "grey white--text");
        },
        nowPath() {
            return (name, plug) => `now/${name}/${plug}`;
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>